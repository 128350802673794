'use client'

import { useCallback } from 'react'

import { fetchCheckUserCommunityPermission } from '@/services/community'
import { isMobileApp } from '@/lib/is-mobile'
import { getEnableCommunity, getEnableCommunityMobileApp } from '@/configs/utils'
import { HubsStore } from '@/types/store'

const useGetCommunityToggle = () => {
  return useCallback(async (credentials: HubsStore['credentials']) => {
    const htcToken = credentials?.htcToken
    const enableCommunity = getEnableCommunity()
    const enableCommunityMobileApp = getEnableCommunityMobileApp()

    if (!enableCommunity) {
      return false
    }

    if ((await isMobileApp()) && !enableCommunityMobileApp) {
      return false
    }

    const hasPermission = await fetchCheckUserCommunityPermission(htcToken!)
    const communityToggle = hasPermission ? true : false

    return communityToggle
  }, [])
}

export default useGetCommunityToggle
