'use client'

import { useCallback } from 'react'
import { createCredentials, removeKeysFromSearchParams } from '@/lib/auth'
import { enableAuthCookieCheck } from '@/configs/utils'
import useHandleLoginCookie from '@/hooks/auth/useHandleLoginCookie'
import useHandleProfileResult from '@/hooks/auth/useHandleProfileResult'
import { CredentialsSchema } from '@/schemas/auth'
import useIsSignedIn from '@/hooks/auth/useIsSignedIn'
import { SEARCH_PARAM_KEYS } from '@/constants/auth'

const useCheckAuth = () => {
  const [isSignedIn, setIsSignedIn] = useIsSignedIn()

  const handleLoginCookie = useHandleLoginCookie()
  const handleProfileResult = useHandleProfileResult(setIsSignedIn)

  const checkAuth = useCallback(async () => {
    const qs = new URLSearchParams(location.search)

    try {
      if (enableAuthCookieCheck()) {
        await handleLoginCookie(qs)
      }

      const credentials = createCredentials(qs)
      if (CredentialsSchema.safeParse(credentials).success) {
        await handleProfileResult(credentials)
      }
    } catch (error) {
      console.error('Error while checking auth: ', error)
      // TODO: handle error
      // goErrorPage()
    } finally {
      removeKeysFromSearchParams(Object.values(SEARCH_PARAM_KEYS))
    }
  }, [handleLoginCookie, handleProfileResult])

  return {
    isSignedIn,
    setIsSignedIn,
    checkAuth,
  }
}

export default useCheckAuth
