import { useCallback } from 'react'
import { useConfigsContext } from '@/context/ConfigsContext'
import { getLoginStatus } from '@/services/auth'
import { SEARCH_PARAM_KEYS } from '@/constants/auth'

const { RETRY_AFTER, HTC_TOKEN, EMAIL, RETICULUM_TOKEN } = SEARCH_PARAM_KEYS

const useHandleLoginCookie = () => {
  const { credentials, removeStore } = useConfigsContext()
  const token = credentials?.token

  return useCallback(
    async (qs: URLSearchParams) => {
      const res = await getLoginStatus()
      if (!res) {
        token && removeStore()
      } else {
        qs.set(RETRY_AFTER, res[RETRY_AFTER].toString())
        qs.set(HTC_TOKEN, res[HTC_TOKEN])
        qs.set(EMAIL, res[EMAIL])
        qs.set(RETICULUM_TOKEN, res[RETICULUM_TOKEN])
      }
    },
    [token, removeStore],
  )
}

export default useHandleLoginCookie
