'use client'

import Script from 'next/script'
import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react'
import { BI_APP_ID, BI_SDK_LOADED_EVENT, BI_VERSION } from '@/constants/bi-sdk'
import { getBISdkUri } from '@/configs/utils'
import { getDevice } from '@/lib/is-mobile'
import { useConfigsContext } from '@/context/ConfigsContext'

const getTMS = () => Date.now()

const getTZ = () => new Date().getTimezoneOffset() * -1 * 60 * 1000

type LoggerOptions = {
  ev: string
  category: string
  account_id?: string
  a1?: string
  a2?: string
  a3?: string
}

type BISdkContextType = {
  sendBIReport: (options: LoggerOptions) => void
  isLoadedBISdk: boolean
}

const BISdkContext = createContext<BISdkContextType | null>(null)

export const BISdkProvider = ({ children }: PropsWithChildren) => {
  const biSdkUri = getBISdkUri()
  const [biSdkLogger, setBISdkLogger] = useState<BISdkLogger | null>(null)
  const { getHTCAccountId } = useConfigsContext()
  const isLoadedBISdk = biSdkLogger !== null

  const sendBIReport = useCallback(
    (options: LoggerOptions) => {
      if (biSdkLogger === null) {
        throw new Error('BI_SDK is not loaded')
      }

      biSdkLogger.log({
        ...options,
        bi_data_point_version: BI_VERSION,
        tms: getTMS(),
        tz: getTZ(),
        account_id: getHTCAccountId(),
        a0: getDevice(),
      })
    },
    [biSdkLogger, getHTCAccountId],
  )

  const handleLoad = () => {
    const biSdkLogger: BISdkLogger = VrBiJs.getLogger(BI_APP_ID)
    setBISdkLogger(biSdkLogger)

    console.log('✅ BI_SDK loaded')
    window.dispatchEvent(new CustomEvent(BI_SDK_LOADED_EVENT))
  }

  const handleError = () => {
    throw new Error('Failed to load BI_SDK' + biSdkUri)
  }

  return (
    <BISdkContext.Provider value={{ sendBIReport, isLoadedBISdk }}>
      {children}
      <Script src={biSdkUri} onLoad={handleLoad} onError={handleError} />
    </BISdkContext.Provider>
  )
}

export const useBISdkContext = () => {
  const value = useContext(BISdkContext)

  if (value == null) {
    throw new Error('useBISdkContext cannot be used outside of BISdkProvider')
  }

  return value
}
